<template>
  <dx-util-popup
    ref="sankeyChartPopupRef"
    height="100vh"
    width="100vw"
    :drag-enabled="false"
    :close-on-outside-click="true"
    :show-close-button="true"
    :show-title="true"
    :title="popupTitle"
    :full-screen="true"
    :position="'center'"
    @shown="sankeyChartPopupShown"
    @hidden="sankeyChartPopupHidden"
  >
    <div class="d-flex justify-content-center">
      <DxSankey
        id="sankey"
        :data-source="data"
        palette="Bright"
        source-field="source"
        target-field="target"
        weight-field="weight"
        :title="`Profit & Lost Overview By Category`"
      >
        <DxNode
          :width="20"
          :padding="30"
        />
        <DxTooltip
          :enabled="true"
          :customize-link-tooltip="customizeLinkTooltip"
          :format="'$ #,##0.##'"
          :z-index="13000"
        />
        <DxLink color-mode="gradient" />
        <DxExport :enabled="true" />
      </DxSankey>
    </div>
  </dx-util-popup>
</template>

<script>
import DxSankey, {
  DxNode,
  DxLink,
  DxTooltip,
  DxExport,
} from 'devextreme-vue/sankey'
// import { Notify } from '@/@robustshell/utils'

export default {
  components: {
    DxSankey,
    DxNode,
    DxLink,
    DxTooltip,
    DxExport,
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    chartData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      popupTitle: '',
      data: [],
    }
  },
  computed: {
    sankeyChartPopup() {
      return this.$refs.sankeyChartPopupRef.instance
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.data = this.transformDataForSankey(this.chartData)
        this.sankeyChartPopup.show()
      },
    },
  },
  methods: {
    transformDataForSankey(data) {
      const transformedData = []

      data.sort((a, b) => b.revenue - a.revenue)

      data.forEach(item => {
        const source = item.productCategory
        const target = 'Revenue'
        const weight = item.revenue

        transformedData.push({
          source: source,
          target: target,
          weight: parseFloat(weight),
        })
      })

      let profitWeight = 0
      let totalCostsWeight = 0
      let shippingFeeWeight = 0
      let referralFeeWeight = 0
      let totalCOGSWeight = 0
      let prepFeeWeight = 0
      let planFeeWeight = 0
      let fbafeeWeight = 0
      let miscFeeWeight = 0

      data.forEach(item => {
        profitWeight += item.profit
        totalCostsWeight += item.totalCosts
        shippingFeeWeight += item.mfnShipping + item.shippingFee
        referralFeeWeight += item.referralFee
        totalCOGSWeight += item.totalCOGS
        prepFeeWeight += item.prepFeeWeight
        planFeeWeight += item.planFee
        fbafeeWeight += item.fbafee
        miscFeeWeight += item.miscFee
      })

      transformedData.push({
        source: 'Costs',
        target: 'COGS',
        weight: parseFloat(totalCOGSWeight),
      })
      transformedData.push({
        source: 'Costs',
        target: 'Referral Fee',
        weight: parseFloat(referralFeeWeight),
      })
      transformedData.push({
        source: 'Costs',
        target: 'FBA Fee',
        weight: parseFloat(fbafeeWeight),
      })
      transformedData.push({
        source: 'Costs',
        target: 'Plan Fee',
        weight: parseFloat(planFeeWeight),
      })
      transformedData.push({
        source: 'Costs',
        target: 'Prep Fee',
        weight: parseFloat(prepFeeWeight),
      })
      transformedData.push({
        source: 'Revenue',
        target: 'Costs',
        weight: parseFloat(totalCostsWeight),
      })
      transformedData.push({
        source: 'Costs',
        target: 'Shipping Fees',
        weight: parseFloat(shippingFeeWeight),
      })
      transformedData.push({
        source: 'Costs',
        target: 'Misc Fees',
        weight: parseFloat(miscFeeWeight),
      })
      transformedData.push({
        source: 'Revenue',
        target: 'Profit',
        weight: parseFloat(profitWeight),
      })

      return transformedData
    },
    customizeLinkTooltip({ source, target, weight }) {
      return {
        html: `<b>From:</b> ${source}<br/><b>To:</b> ${target}<br/><b>Amount:</b> $${weight}`,
      }
    },
    sankeyChartPopupShown() {
      this.popupTitle = 'Cost and Profit Distribution'
    },
    sankeyChartPopupHidden() {
    // Clear form when popup is closed
    },
    closePopup() {
      this.sankeyChartPopup.hide()
    },
    handleSubmit() {
      this.$emit('emit-form-saved')
      this.closePopup()
    },
  },
}
</script>

<style lang="scss">
#sankey {
  height: 95vh;
  width: 95vw;
}
</style>
